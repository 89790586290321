.category{
    $this: &;
    a{
        text-decoration: none;
    }

    &__image{
        &--product{
            padding-bottom: 100% !important;  
            background-color: $concrete;
        }
        width: 100%;
        height: 0;
        position: relative;
        background-color: $mine-shaft;
        padding-bottom: calc(0.50 * 100%); 

        @include media-breakpoint-up(sm) {
            padding-bottom: calc(0.75 * 100%); // formato 4/3 = 0.75 ; 16/9 = 0.5625
        }

        &--light{
            background-color: transparent;
        }
        
        .withLoader{
            width: 100%;
            height: 100%;
            position: absolute;
            &:not(.withLoader--light)::before{
                background-color: rgba($black, .4);
                position:absolute;
                top:0;
                left:0;
                content:"";
                width:100%;
                height:100%;
            }
        }

        .withLoader--light{
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    &__icon{
        display:block;
        margin: 5px 20px;
        width:70px;
    }

    &__preTitle {
        font-weight: $font-weight-normal !important;
        margin-bottom: 0;
        line-height: 25px;
        font-size: 16px;
    }

    &__title {
        font-weight: $font-weight-normal !important;
        margin:0;
        a {
            text-decoration: none;
            text-align: center;
            margin: auto;
        }
    }

    &__click {
        cursor: pointer;
        line-height: 30px;
        transition: right 400ms linear;
        font-weight: $font-weight-semibold;
        //transform: translateY(50%);
        display: inline-block;
        color: $theme-color;
        i {
            margin-left: 10px;
        }
    }


    &__product{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        opacity: 0;
        &--loaded{
            opacity: 1;
        }

        #{$this}__prdName{
            font-family: $font-secondary;
            text-transform: uppercase;
            font-size: 20px;
        }

        #{$this}__prdLink {
            opacity: 0;
            transition: opacity 400ms linear;
            color: $theme-color;
        }

        #{$this}__img{
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            padding-bottom: 100%;
        } 

        &--grid{ 

            #{$this}__item { 
                flex: 0 1 auto;
                padding: 5px;
                margin: 0 15px 25px;
                width: calc(100% - 30px);
                transition: box-shadow 400ms linear;
                box-shadow: 0 0 15px rgba($white, .3);

                @include media-breakpoint-up(xs) {
                    width: calc(50% - 30px);
                }

                @include media-breakpoint-up(md) {
                    width: calc(33.33332% - 30px);
                }

                &:hover{
                    box-shadow: 0 0 15px rgba($black, .3);
                    #{$this}__prdLink{
                        opacity: 1;
                    }
                }
                &--small{

                    @include media-breakpoint-up(sm) {
                        width: calc(33.33332% - 30px);
                    }
    
                    @include media-breakpoint-up(md) {
                        width: calc(25% - 30px);
                    }
                }

            }

            #{$this}__prdName{
                text-align: center;
                margin-top: 10px;
                font-size: 16px;
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
            }

            #{$this}__prdDesc {
                display: none; 
            }

            #{$this}__prdLink{
                display: none;
            }
        }

        &--list{ 
            margin: 0 10px;
            #{$this}__item { 
                padding: 5px;
                display: flex;
                width: 100%;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            #{$this}__image {
                flex: 0 0 100px;
                padding-bottom: 100px !important;
                margin-right: 10px;
                @include media-breakpoint-up(sm) {
                  flex: 0 0 250px;
                  padding-bottom: 250px !important;
                  margin-right: 20px;
                }
            }

            #{$this}__prdLink{
                opacity: 1;
            }

            #{$this}__text {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            #{$this}__prdDesc{
                color: $dove-gray;
            }
        }
    }

    &__cat-type {
       margin: 0 21px;
       display: flex; 
       padding: 0;
       width: 100%;
       align-items: center;
       list-style: none;
       justify-content: flex-end;
       margin-bottom: 15px;
    }

    &__cat-item {
        margin-left: 10px;
        cursor: pointer;
        opacity: 0.3;
        &.active{
            opacity: 1;
        }
        img{
            max-width: 20px;
        }
    }

    &__back{
        display: block;  
        color: $theme-color;  
        font-weight: $font-weight-bold;
        text-decoration:none !important; 
        i{
            padding-right:5px; 
        } 
    } 
    &__seo{
        padding-bottom: 30px;
    }
} 