// Form contatti
.contact{
    background-color: $theme-gray;
    padding: 60px 30px;
    p, label{
        color: $dove-gray;
    }
    p:empty{
        margin:0;
        display:none;
    }
    small{
        display: block;
        color: $theme-color;
    }
    
    &__title{
        font-size: 26px;
        font-weight: $font-weight-bold;
    }

    &__btn{
        margin-top: 30px;
    }

    &__link{
        text-decoration: none !important;
        color: $theme-color;
        font-style: italic;
        display: block;
        
        @include media-breakpoint-up(lg) {
            text-align: right;
            
        }
    }
} 