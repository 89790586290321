.gallery {
    $this: &;
    padding: 0;
    list-style: none;
    margin: 0 30px 0;


    // nascondo gallery prima del caricamento
    visibility:hidden;
    opacity: 0;	 
    transition: opacity 1s;

    &.slick-initialized {
        visibility:visible;
        opacity:1;
    }

    @include media-breakpoint-up(md) {
        margin: 0 -10px 0;
    }

    &__item {
        $this: &;
        margin: 10px;
        height: inherit !important;

        &--correlati{
            cursor: pointer;
            &:hover h3 {
                color: $theme-color;
            }
        }

        // nascondo tutti gli item tranne il primo in fase di caricamento gallery
        display: none;
        &:first-child {
            display: block; 
            visibility: hidden;
        }

        &__content {
            text-decoration: none !important;
            width: 100%;
            height: 100%;
            transition: box-shadow 0.3s linear;
            display: inline-block;
            &:hover span{
                color: $theme-color;
            }

            &--no100{
                height: auto;
            }

            @include media-breakpoint-up(lg) {
                min-height: auto;
            }

            &--transparent{
                @include media-breakpoint-up(md) {
                    background-color: transparent !important;
                }
            }

            &__image {
                margin-bottom: 10px;

                &--full{
                    max-width: 450px;
                    margin:10px 0 20px;
                    img{
                        width:100%;
                    } 
                }
            }

            &__title {
                font-size: 20px;
                font-weight: 500;
                display: block;
                margin: 10px 0 5px;
                color: $mine-shaft;
                transition: color 0.3s linear;
            }

            &__btn {
                color: $theme-color !important;
                font-weight: $font-weight-normal;
                i {
                    display: none;
                    @include media-breakpoint-up(lg) {
                        display: inline;
                    }
                }
            }

            &__link {
                position: relative;
                display: block;
                height: 100%;

                img {
                    height:100%;
                }

                &--product {
                    display: flex;
                    border: 1px solid $theme-gray;
                    img {
                        height: auto;
                        margin: auto;
                        object-fit: contain;
                        &.orizzontale{
                            width: 100%;
                            
                        }
                    }
                }
            }

            &__didascalia{
                text-align: center;
                font-weight: $font-weight-bold;
            }

            &__prdName {
                text-align: center;
                margin-top: 10px;
                font-family: $font-secondary;

                &--correlati{
                    font-size: 28px;
                    line-height: 1.2;
                    text-align: left;
                    transition: color 0.3s linear;
                    color: $mine-shaft;
                }
            }

            &__prdLink{
                text-align: center;
                display: block;
                text-decoration: none;
                color: $theme-color;
                transition: opacity 400ms linear;
                opacity: 0
            }

            &__error{
                margin: auto;
            }
        }

        &__small-img{
            height: calc(33.3332% - 15px);
            margin-bottom: 15px;
            position: relative;
            display: block;
            position: relative;
            img{
                object-fit: cover;
                height:100%;
                height:100%;
            }
            .fa-youtube-play{
                font-size: 100% !important;
            }
        }

        &__text{
            p {
                margin-bottom:0;
            }
        }

    }

    // mostro tutti gli item una volta caricata la gallery
    &.slick-initialized .gallery__item {
        display: block;
        visibility: visible !important;
    }


    &--thumb{
        height:100%;

        .slick-list, .slick-track {
            height:100%;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1000;
        height: 25px;
        width: 25px;
        font-size: 20px;
        top: 50%; 
        &.slick-disabled{
            display:none !important;
        } 

        &--white{
            color: $white;
        }
    }

    &__prev {
        left: -25px; 
        &--top{
            top: -30px; 
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__next {
        right: -35px;
        &--bottom{
            top: auto;
            bottom: -15px; 
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // Forzo la slider con tutti gli elementi alti uguali
    .slick-track {
        display: flex !important;
    }

    .slick-slide{
        height: inherit !important;
    }

    .slick-slide > div { height: 100%; }

    // Formatto icone sopra le varie slider 
    .fa-youtube-play, .fa-search{
        color: $white;
        position: absolute;
        text-shadow: 0 0 20px rgba($black, .8);
        font-size: 24px;
        z-index:100;
        &.black {
            text-shadow: none;
            color: $black;
        }
    }

    .fa-search {
        right: 10px;
        bottom: 10px;
    }

    .fa-youtube-play{
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 50px;
    }
 }