.footer {
    background-color: $lynch;
    padding: 30px 0 100px;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.5;
    font-weight: $font-weight-normal;
    color: $white;

    @include media-breakpoint-up(md) {
        padding: 30px 0;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }

    p, a {
        color: inherit;
        line-height: 1.5;
        text-decoration: none !important;
    }

    &__info{
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &__logo{
        flex: 0 1 auto;
        margin: 0 30px 30px 0;
        width: 85px;
        img {
            width: 100%;
        }
    }

    &__links {
        margin-bottom: 20px;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        opacity: .7;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &__link{
        margin-right: 30px;
    }

    &__social{
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: $font-weight-semibold;

        @include media-breakpoint-up(lg) {
            font-size: 21px;
        }
        &__item {
            padding: 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba($white, .25);
            &:last-child{
                border-bottom: none; 
            }
        }
        p {
            margin:0;
            &:first-child{
                flex: 0 1 auto;
                margin-right: 30px;
            }
        }
    }
}