.header {
    padding: 20px 0;
    background-color: $white;
    box-shadow: 0 0 20px rgba($black, .1);

    &__logo {
        display: block;
        img {
            width: 210px;
            @include media-breakpoint-up(lg) {
                width: 190px;
            }
            
            @include media-breakpoint-up(xl) {
                width: 210px;
            }
        }

        &--flex {
            @include media-breakpoint-down(md) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &__hamburger{
        height: 30px;
        width: 30px;
        margin-left: 30px;
        background-image: url('/wp-content/themes/mmone_theme/images/menu.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0 0;
        cursor: pointer;
        &.open {
            background-position: 0 -30px;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    
    &__wrapper{
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include media-breakpoint-up(xxl) {
            padding:0 20px;
        }
    }

    &__main-menu {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
        display: none;
        height: calc(100vh - #{$pad-top});

        @include media-breakpoint-up(lg) {
            display: flex;
            height: auto;
        }

        &__item {
            font-weight: $font-weight-normal;
            font-size: $menu-xl-font-size;
            padding: 30px 0 0;

            display: block;
            @include media-breakpoint-up(lg) {
                padding: 0;
                margin: 0 8px;
                font-size: $menu-lg-font-size;
            }

            @include media-breakpoint-up(xl) {
                margin: 0 15px;
                font-size: $menu-xl-font-size;
            }

            &:last-child {
                padding-bottom: 30px;
                @include media-breakpoint-up(lg) {
                    padding-bottom: 0;
                    display: none;
                }
            }
        }

        &__link {
            text-decoration: none !important;
            &.active{
                color: $theme-color;
            }
        }
    }

    &__btn{
        display:none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
        
        a {
            font-size: $menu-lg-font-size !important;
            @include media-breakpoint-up(xl) {
                font-size: $menu-xl-font-size !important;
            }
        }
    }
}
