h1,
.h1 {
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    line-height: 30px;
    font-size: 24px;

    @include media-breakpoint-up(md) {
        line-height: 36px;
        font-size: 26px;
    }

    @include media-breakpoint-up(lg) {
        line-height: 36px;
        font-size: 28px;
    }

}

h2,
.h2 {
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    line-height: 27px;
    font-size: 22px;

    @include media-breakpoint-up(md) {
        line-height: 36px;
        font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
        line-height: 30px;
        font-size: 26px;
    }
}

h3,
.h3 {
    font-family: $font-secondary;
    font-weight: $font-weight-semibold;
    line-height: 27px;
    font-size: 20px;

    @include media-breakpoint-up(lg) {
        line-height: 30px;
        font-size: 22px;
    }
}

h3,
.h3 {
    font-family: $font-primary;
    font-weight: $font-weight-semibold;
    line-height: 27px;
    font-size: 20px;
    @include media-breakpoint-up(lg) {
        line-height: 30px;
        font-size: 22px;
    }
}

.visual-title{
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    line-height: 30px;
    font-size: 26px;

    @include media-breakpoint-up(md) {
        line-height: 32px;
        font-size: 30px;
    }

    @include media-breakpoint-up(lg) {
        line-height: 44px;
        font-size: 40px;
    }
}

a {
    color: $black;
    transition: color 0.2s linear;
    text-decoration: underline;
    &:hover {
        color: $theme-color;
    }
}

strong {
    font-weight: 600;
}


.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


// .info-assistenza{
//     padding: 0;
//     margin: 30px 0 0;
//     list-style: none;
//     li{
//         margin: 15px 0;
//         display: flex;
//         align-items: center;
//     }
//     i{
//         color: $theme-color;
//         width: 30px;
//         text-align: center;
//         margin-right: 15px;
//         font-size: 32px;
//     }
//     a{
//         text-decoration: none;
//     }
// }

// .text{
//   ul{
//     margin:10px 0;
//     //margin-bottom:$padding-generale;
//     li{
//       font-size:$base-font-size;
//       color: $base-font-color;
//       font-weight:400;
//       padding-left:15px;
//       background:url("../imgs/bullet.gif") no-repeat 0 6px transparent;
//       &::first-letter {
//         text-transform:uppercase;
//       }
//     }
//   }
// }