.ricoperture {
    background-color: $mine-shaft; 
    // background-image: none !important;
    position: relative;

    .withLoader{
        width: 100%;
        height: 100%;
        position: absolute;

        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: calc(0.30 * 100%);
        // background-size: cover; background-repeat: no-repeat; background-attachment: fixed;
    }

    &__item{
        
        padding: 40px 0;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-up(lg) {
            padding: 0;
            position:absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-image: none !important;
        }

        &:nth-child(2){
            left: 50%;
        }
    }


    &__preTitle{
        color: $white;
        line-height: 1;
        font-size: 35px;
        font-weight: $font-weight-black;

        @include media-breakpoint-up(xl) {
            font-size: 45px;
        }
    }

    &__title {
        color: $theme-color;
        text-transform: uppercase;
        margin: 0;
        font-size: 120px;
        line-height: .9;
        text-shadow: 2px 2px 0px rgba(0,0,0, .45);

        @include media-breakpoint-up(xl) {
            font-size: 200px;
        }
    }

    &__desc{
        color: $white;
        line-height: 1.3;
        font-size: 24px;

        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }
    }

} 