.paginazione{
    text-align: center;
    margin: 30px 0;

    .page-numbers {
        text-decoration: none;
        background-color: $white;
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        color: $theme-color;
        width: 40px;
        height: 40px;
        line-height: 40px;
        &.current {
            background-color: $theme-color !important;
            color: $white;
        }
        &:hover{
            background-color: $alto;
        }
    }
}
