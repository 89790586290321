.sceglierci{
    $this: &;
    padding: 100px 0;

    &--border{
        margin-bottom: 0;
        padding-bottom: 100px;
    }
    
    &__blocco{
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) { 
            flex-direction: row;
        }
    }

    &__img{
        order: -1;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) { 
            order: 1;
            flex: 0 1 20%;
            margin-left: 50px;
            margin-bottom: 0;
        }
        img {
            max-height: 150px;
            @include media-breakpoint-up(lg) { 
                max-height: none;
            }
        }
    }

    &__content{
        flex: 1;
    }

    &__title {
        font-size: 42px;
        line-height: 1.2;
        font-weight: $font-weight-black;
        text-align: center;
        @include media-breakpoint-up(lg) { 
            text-align: left;
        }
    }

    &__desc{
        text-align: center;
        @include media-breakpoint-up(lg) { 
            text-align: left;
        }
    }

    &:nth-of-type(even){
        background-color: $wild-sand;

        #{$this}__img{

            @include media-breakpoint-up(lg) { 
                order: -1;
                margin-right: 50px;
                margin-left: 0;
            }
        }
    }
}