// .whatsapp {
//     position: fixed;
//     right: 30px;
//     bottom: 30px;
//     width: 60px;
//     height: 60px;
//     background-color:$theme-wa-color;
//     border-radius: 50%;
//     display: flex; 
//     z-index: 1000000000;
//     box-shadow: 0 0 20px rgba(0,0,0, .15);
//     @include media-breakpoint-up(lg) {
//         display: none;
//     }
//     &__wrapper{
//         margin: auto;
//         color: $white;
//         font-size: 32px;
//     }
// }

.whatsapp-tip{
    position: fixed;
    bottom: 90px;
    right: 0px;
    background: $theme-gray;
    padding: 5px 15px 5px 0px;
    background: $theme-wa-bg;
    color: #000;
    font-weight: 600; 
    font-size: 14px;
    border-radius: 10px 0px 0px 10px;
    border: 3px solid $theme-wa-bg;
    z-index: 9999;
    a{
        text-decoration: none;
    }
    img {
        width: 32px;
        margin-right: 5px;
    }
}  