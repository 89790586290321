html {
    overflow-x: hidden;
    box-shadow: none !important;
    -webkit-font-smoothing: antialiased;
    &.menu-open {
        overflow: hidden;
    }
}

body {
    overflow-x: hidden;
    background-color: $white;
    color: $color-font-default;
    font-family: $font-secondary;
    line-height: $body-line-height;
    margin: 0;
 
    font-size: $body-font-size-small;
    @include media-breakpoint-up(md) {
        font-size: $body-font-size;
    }

    a {
        outline: none !important;
    }

    &.menu-open {
        overflow-y: scroll;
        max-height: 100vh;
        header {
            overflow-y: scroll;            
            max-height: 100vh;
        }
    }
}

.site-content {
    margin-top: $pad-top;
    padding-top: 60px;
    @include media-breakpoint-between(lg, xl) {
        margin-top: $pad-lg-top;
    }
    &--home,
    &--ricoperture{
        padding-top:0;
    }
}

.no-pad{
    padding: 0 15px 0 0; 
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
        padding: 0; 
    } 
}

section{
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-bottom: 65px;
    } 

    &:last-child {
        margin-bottom: 0;
    }

    &.nomargin {
        margin-bottom: 0;
    }
}

.parallax-disable{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
}
 
//STICKY FOOTER

body, #barba-wrapper, .website-wrap {
    min-height: 100vh;
}

.website-wrap > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.site-content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}

