.button {
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 5px;
    display: inline-block;
    color: $white;
    cursor: pointer;
    font-weight: $font-weight-normal;
    text-decoration: none !important;
    font-family: $font-secondary;
    font-size: $body-font-size;
    border: 2px solid transparent;
    transition: background-color .3s linear, box-shadow .3s linear, color .3s linear;
    @include media-breakpoint-up(lg) {
        padding: 15px 25px;
        font-size: 20px;
    }
    &.orange {
        border: 2px solid $theme-color;
        color: $white;
        background-color: $theme-color; 
    }
    &.white {
        background-color: $white;
        border: 2px solid $theme-color;
        color: $theme-color;
        &:hover{
            background-color: $theme-color;
            color: $white;
        }
    
    }
    &:hover {
        color: $theme-color;
        background-color: $white;
    }
}