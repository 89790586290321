/*Chat*/
.chat {
    $this: &;
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
        position: fixed;
        bottom: 100px;
        right: -800px;
        z-index: 1000;
        transform: translateX(calc(100% - 80px));
        transition: all 300ms ease-out;
    }

    &__wrapper{
        display: flex;
        background-color: $concrete; 
        padding: 15px 0 15px 15px;
        box-shadow: 0 0 20px rgba(0,0,0, .15);
        align-items: center;
    }

    &__txt{
        padding: 0 30px 0 15px;
        max-width: 300px; 
    }

    &__link{
        max-width: 300px;
        text-decoration: none !important;
        font-size: 21px;
    }

    &__img {
        width: 50px;
        box-sizing: border-box;
        cursor: pointer;
        img {
            width:100%;
        }
    }

    &__close{
        position: absolute;
        top: 0;
        right: 0;
        background-color: $theme-color;
        color: $white !important; 
        cursor: pointer;
        padding: 10px;
    }

    &--open{
        transform: translateX(0);
    }

    &--visible {
        right: 0;
    }


    // Mobile popup
    &__mobile{
        position: fixed;
        bottom: -400px;
        left:0;
        width: auto;
        z-index: 1010;
        background-color: $theme-color;
        display: flex;
        //box-shadow: 0 0 20px rgba(0,0,0, .15);
        border-radius: 0 5px 5px 0;
        box-shadow: 0 0 4px 1px rgba($black, .35);
        transition: all 300ms ease-out;
        cursor: pointer;

        &--visible {
            bottom: 15px;
        }

        &__item{
            flex: 0 1 auto;
            padding: 10px 20px;
            border-right: 1px solid $white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            overflow: hidden;
            

            /*@include media-breakpoint-up(sm) {
                font-size: 20px;
                padding: 20px;
            }*/

            &:last-child{
                border-right: none;  
            }
        }

        &__link{
            color: $white !important;
            text-decoration: none !important;
        }

        &__icon{
            font-size:40px;
            color: $white;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}