.scorciatoie{
    
    &--gray{
        background-color: $concrete;
        padding: 50px 0;

        @include media-breakpoint-up(lg) {
            padding: 100px 0;
        }
    }

    &__block{
        display: block;
        text-decoration: none !important;
        box-shadow: 0 0 20px rgba(0,0,0, 0);
        transition: box-shadow 400ms linear;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            height: 100%;
        }
        &:hover {
            box-shadow: 0 0 20px rgba(0,0,0, .15);
        }
    }

    &__title, &__img {
        margin-bottom: 20px;
        text-align: center;
        display: block;
        color: $fiord;
    }


    &__desc {
        color: $fiord; 
        display: block;
        text-align: center;
        margin-bottom: 0;
    }

    &__link {
        color: $fiord !important;
        text-decoration: none !important;
    }

    &__imgBottom {
        max-width: 120px;
        margin: 0 auto; 
        margin-bottom: 30px;       
        @include media-breakpoint-up(md) {
            max-width: none;
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }

    &__titleBottom {
        font-weight: $font-weight-black;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 30px;
        color: $fiord;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 55px;
            text-align: left;
        }
    }

    &__descBottom {
        margin-bottom: 30px;
        color: $fiord;
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 100px;
        }

    }
}