.counterup{
    padding-top: 30px;
    &__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    &__item {
        flex: 1 0 0px;
        min-width: 180px;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: 15px;
        padding: 20px;
        box-shadow: 0 0 20px rgba($black, .10);
    }

    &__title{
        text-align: center;
        font-size: 42px;
        line-height: 1.2;
        font-weight: $font-weight-black;
        color: $theme-color;
    }

    &__number {
        margin-bottom: 15px;
        font-weight: $font-weight-semibold;
        .counter{
            font-size: 30px;
            font-weight: $font-weight-black;
        }
    }
}