// Variabile media query XXS
$screen-xxs-max:480px;

$grid-breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

$white:         #fff;
$black:         #000;
$theme-color:   #F58318;
$sandy-brown:   #F49957;
$mine-shaft:    #262626; 
$theme-gray:    #ededed;
$san-marino:    #3F799D;
$alto:          #D9D9D9;
$dove-gray:     #707070;
$fiord:         #47555C;
$concrete:      #f2f2f2;
$sandwisp:      #F7F2B1;
$lynch:         #63818E;
$wild-sand:     #f5f5f5;





$bg-footer: $mine-shaft !default;
$color-font-default: $fiord !default;
$main-nav-color: $theme-gray;


// Easings
$mm-easing-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
$mm-easing-bounce:cubic-bezier(0, 1.8, 1, 1.8);

// Social
$theme-fb-color: #3a589b;
$theme-tw-color: #568bc9;
$theme-gp-color: #d6492f;
$theme-pt-color: #ca4638;
$theme-yt-color: #C3181E;
$theme-rss-color:#e19126;
$theme-wa-color: #25d366;
$theme-wa-bg:    #c0e65b;

// =============================================================================
// TYPOGRAPHY
// =============================================================================
$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Raleway', sans-serif;
$body-font-size: 18px;
$body-font-size-small: 16px;
$root-font-size: 10px;
$menu-lg-font-size: 17px;
$menu-xl-font-size: 20px;
$body-line-height: 1.5;

// weights
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;

// Padding:
$pad-xs-top: 68px; 

$pad-top: 120px;
$pad-lg-top: 112px;
$pad-top-section: 90px;

@function max($numbers...){
    @return m#{a}x(#{$numbers})
} 
  
@function min($numbers...){
   @return m#{i}n(#{$numbers})
}  