.visual{
    &__wrapper{
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    &__item{
        flex: 0 1 100%;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            flex: 0 1 50%;
            order: 2;
            &:nth-child(1), &:nth-child(2), &:nth-child(4){
                order: 1;
            }
        }

        @include media-breakpoint-up(lg) {
            flex: 0 1 33.3333%;
            order: 0 !important;
        }
    }

    &__image{
        width: 100%;
        height: 0;
        position: relative;
        background-color: $mine-shaft; 
        padding-bottom: calc(0.5916 * 100%); 
        
        .withLoader{
            width: 100%;
            height: 100%;
            position: absolute;
            transition: transform 400ms linear;
            &:hover{
                transform: scale(1.1, 1.1);
            }
        } 
    }

    &__title{
        position: absolute;
        text-align: center;
        transform: translate(-50%, -50%);
        pointer-events: none;
        top: 50%;
        left: 50%;

    }

    &__link{
        color: $white;
        text-decoration: none; 
        font-weight: $font-weight-normal;
        line-height: 1.1;
        text-decoration: none;
        span {
            display: block;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            font-size: 180%;
        }
    }

    &__msg {
        width: 100%;
        order: 3;
        background-color: $white;
        padding: 20px;
        background-color: $concrete;
        
        @include media-breakpoint-up(lg) {
            position: absolute;
            background-color: $white;
            width: auto;
            padding: 10px 30px;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
        }

        @include media-breakpoint-up(xxl) {
            padding: 20px 30px;
        }
    }

    &__ctaTitle{
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 10px !important;
        font-weight: $font-weight-black;
        color: $fiord;

        @include media-breakpoint-up(lg) {
            white-space: nowrap;
            font-size: 30px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 42px;
        }
    }

    &__ctaLink {
        color: $theme-color;
        text-decoration: none !important;
        font-weight: $font-weight-bold;
        font-size: 20px;
    }
}