// Lazy load immagini di sfondo
.withLoader{
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

[data-lazy] {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 0.2s ease-in-out;
  &.withLoader{  
    &:after{
      transition: all 0.2s ease-in-out;
      content:'';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #1b1b1b url(/images/lazy-loader-black.gif) no-repeat center center;
      z-index: 1;
      opacity: 1;
      left:0;
      top: 0;
    }
    &.loaded{
      &:after{
        opacity: 0;
      }
    }
    &--light:after{
      background: #f2f2f2 url(/images/lazyLoader.gif) no-repeat center center;
    }
  }
}
 
.lazy-loader-bar {
  position: relative;
  &:before{
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 300px;
    height: 1px;
    background:fade(#000,40%);
    animation:lazy-loader-animation 1s infinite alternate;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    opacity: 1;
    .loaded & {
      opacity: 0;
      animation:none;
    }
  }
}
 
// Animazione loader
@keyframes lazy-loader-animation{
  0% {transform: translate(-50%,-50%) scaleX(0);}
  100% {transform: translate(-50%,-50%) scaleX(1);}
}