.preventivo {
    margin-bottom: 65px !important;
    &__radio {
        .wpcf7-list-item {
            margin-bottom: 20px;
            margin-left: 0;
            position: relative;
            display: block;
            &:first-of-type{
                margin-left: 0;
            }

            &.last{
                margin-bottom: 0;  
            }

            @include media-breakpoint-up(lg) {
                display: inline-block;
                margin-left: 30px;
                margin-right: 20px;
            }
        }
    }  

    &__label {
        font-size: 20px;
        color: $mine-shaft;
        font-weight: $font-weight-normal;
    }

    &__tutorial{
        text-decoration: none !important;
        display: flex;
        align-items: center;
        font-size: 80%;
        margin: 5px 0;
        img{
            margin-right: 5px;
        }
    }

    .form-group {
        margin-bottom:35px;
    }

    .qbutton{
        padding: 10px 20px;
        border: 1px solid $mine-shaft;  
        color: $mine-shaft; 
        font-size: 16px;
        background-color: $white;
        &:hover{
            background-color: $concrete;
        }
    }

    .categorie{
        width: 100%;
        display: block;
        input{
           display: none;
        }
        .wpcf7-radio{
            display: flex;
            margin:0 -10px;
            flex-wrap: wrap;
            align-items: stretch; 
        }

        .wpcf7-list-item-label{
            flex:1;
        }
 
        .wpcf7-list-item {
            border: 2px solid $concrete;
            border-radius: 3px;
            flex: 0 1 auto;
            width: calc(50% - 20px); 
            margin: 10px;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(sm) {
                width: calc(50% - 20px);
            }

            @include media-breakpoint-up(md) {
                width: calc(33.3332% - 20px);
            }

            @include media-breakpoint-up(lg) {
                width: calc(25% - 20px);
            }

            @include media-breakpoint-up(xl) {
                width: calc(20% - 20px);
            }

            &.active{
                border: 2px solid $theme-color;
            }
        }
    }

    &__flex{
        display: flex; 
        align-items: center;
        padding: 10px;  
        cursor: pointer;
    }

    &__img{
        flex: 0 0 50px;
        margin-right: 10px;
        img{
            width: 100%;
            display: block;
        }
    }

    &__text{
        color: $san-marino;
        font-weight: $font-weight-semibold;
    }

} 