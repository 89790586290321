
.tooltip_templates { 
    display: none;  
}

.tooltipster-box {
	background: $concrete;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 5px 5px 20px rgba(0,0,0,.05);
    &-content{
        color: $mine-shaft;
        line-height: 1.5;
    }
}

.tooltip-new {
    background-color: $theme-color;
    color: $white;
    line-height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: -7px;
        right: -20px;
    }
}

.tooltipster-base{
    z-index: 1 !important;
}