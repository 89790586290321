.prodotto{
    padding-bottom: 60px;
    &__back {
        display: inline-flex;
        align-items: center;
        text-decoration: none !important;
        color: $mine-shaft !important;
        *{
            margin-right: 10px;
        }
        span{
            max-width: 150px;
            font-size: 14px;
            line-height: 1.3;
        }
    }

    &__materiale{
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__pdf{
        margin-bottom: 10px;
    }

    &__pdfLink{
        text-decoration: none;
    }

    &__etichetta{
        margin-left: 10px;
    }


    &__tabs{
        margin-top: 50px;
        &__nav{
            display: flex; 
            border-bottom: 0;
        }
        &__item{
            margin-right: 40px;
            .nav-link{
                border:0;
                text-transform: uppercase;
                text-decoration: none;
                font-weight: $font-weight-semibold;
                padding: 10px 0;
                color: $dove-gray;
                &.active, &:hover{
                    color: $black;
                    border-bottom:2px solid $theme-color;
                }
            }
        }

        &__content{
            .content{
                padding: 20px 0;
            }
        }

        &__link{
            text-transform: uppercase;
            text-decoration: none !important;
            font-weight: $font-weight-semibold;
            border-bottom:1px solid $theme-gray;
            padding: 10px 0;
            font-size: 20px;
            display: block;
            &.actives {
                color: $theme-color;
            }
            .fa{
                float:right;
            }

        }
    }

    &__specifiche{
        margin:0;
        padding:0;
        list-style: none;
        p{
            margin:0;
        }
        &__item{
            display:flex;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            div {
                flex:0 1 auto;
                width: 50%;

            }
            &:nth-child(even){
                background-color: #f9f9f9;
            }
        }
    }

    .col-md-7{
        @include media-breakpoint-down(sm) {
            order: -1;
        }
    }

} 