.contatti{

    #leafletMap {
        width: 100%;
        height: 300px;
        border:1px solid $theme-gray;
        @include media-breakpoint-up(sm) { 
            height: 400px; 
            margin-bottom: 30px;
        }
    } 
    
    
}

