.news {
  &__title {
    font-size: 28px;
    line-height: 1.1;
  }

  &__image{
    width: 100%;
    height: 0;
    position: relative;
    background-color: $mine-shaft;
    padding-bottom: calc(0.50 * 100%); 

    @include media-breakpoint-up(sm) {
      padding-bottom: calc(0.75 * 100%); // formato 4/3 = 0.75 ; 16/9 = 0.5625
    }

    &--light{
      background-color: transparent;
    }
    
    .withLoader{
      width: 100%;
      height: 100%;
      position: absolute;
      &:not(.withLoader--light)::before{
        background-color: rgba($black, .4);
        position:absolute;
        top:0;
        left:0;
        content:"";
        width:100%;
        height:100%;
      }
    }

    .withLoader--light{
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &__content {
    cursor: pointer;

    &:hover a{
      color: $theme-color;
    }
  }
}