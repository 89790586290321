.banner {
    background-color: $mine-shaft; 
    min-height: 300px;
    padding-top: 100px;
    padding-bottom: 100px;

    @include media-breakpoint-up(lg) {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    position: relative; 
    display: flex;
    flex-direction: column;
    align-items: center;

    > *{
        text-align: center;
        color: $white;
        padding: 0 20px;
        text-align: center;
        line-height: 1;
        margin: 0;
    }

    &__pretitle{
        font-size: 32px;
        font-weight: $font-weight-bold;
    }

    &__title {
        color: $theme-color;
        margin: 30px 0;
        font-size: 50px;
        text-shadow: 2px 2px 0px rgba(0,0,0, .45);
        @include media-breakpoint-up(lg) {
            font-size: 68px;
        }
    }

    &__descrizione {
        margin-bottom: 30px;
        line-height: 1.4;
        font-size: 26px;
    }
}